<template>
  <b-button @click="doUpdateCoverage" v-html="buttonText"></b-button>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: 'CoverageUpdate',
  props: {
    buttonText: {
      type: String,
      default () {
        return 'Update Coverage'
      }
    },
    id: {
      type: String,
      default () { 
        return null
      }
    }
  },
  methods: {
    ...mapActions([
      'getCoverages',
      'putCoverage'
    ]),
    updateCoverage (data) {
      let addressObj = {
          zip_code:       data.postalCode,
          country:        'United States'
      }
      this.$geocoder.send(addressObj, response => {
        let location = response.results[0].geometry.location
        this.putCoverage({
          id: data.id,
          lat: location.lat.toString(),
          lng: location.lng.toString()
        })
      })
    },
    doUpdateCoverage () {
      let params = {
        'lat[exists]': false
      }
      if (this.id) {
        params.id = this.id
      }
      this.getCoverages(params).then(resp => {
        for (let i in resp) {
          if (!resp[i].postalCode) {
            continue
          }
          this.updateCoverage(resp[i])
        }
      })
    },
  }
}
</script>