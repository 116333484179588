<template>
	<b-modal v-model="show" ref="businessViewModal" :title="business.areaName" size="lg" @hide="$emit('business-view-modal-closed')" ok-only lazy>
	  <b-container fluid>
	   <business-view :business="business" @close="close()" />
	  </b-container>
	  <div slot="modal-footer" class="w-100">
       <b-button-group class="float-right">
	       <b-btn variant="success" @click="show=false">
	         Close
	       </b-btn>
	       <b-btn variant="warning" @click="show=false" :to="{ path: '/business/form', query: { bid: business.id }}">
	         Edit
	       </b-btn>
       </b-button-group>
     </div>
	</b-modal>
</template>

<script>
	import BusinessView from './BusinessView'
	
	export default {
	  name: 'BusinessViewModal',
	  components: {
	    BusinessView
	  },
	  props: {
			bid: Number
		},
	  data () {
	    return {
	      show: false,
        business: {}
	    }
	  },
	  methods: {
	    close () {
	      this.$refs.businessViewModal.hide()
	    }
	  },
	  watch: {
	    bid (val) {
				if (!val) { this.business = {}; return }
				this.$store.dispatch('getBusiness', {id: val}).then(user => {
					this.business = this.$store.getters.business
					this.$refs.businessViewModal.show()
				})
	    }
	  }
	}
</script>