<template>
  <div>
  <b-row>
    <b-col md="6">
      location: {{ business.city }}, {{ business.state }} <br />
      Director: {{ business.primaryContact?.fullName }} (<router-link
        :to="{name: 'userForm', params: {uid: business.primaryContact?.id}}"
      >#{{ business.primaryContact?.id }}</router-link>)<br />
      <span v-for="user in otherDirectors" :key="user.id">Director: {{ user.fullName }} (<router-link :to="{name: 'userForm', params: {uid: user.id}}">#{{ user.id }}</router-link>)<br /></span>
      Email: <a :href="'mailto:' + business.email" :title="'Email ' + business.areaName + ' <' + business.email + '>'">{{ business.email }}</a>
    </b-col>
    <b-col md="6">
      {{ numTerritories }} Territor<span v-if="(numTerritories != 1)">ies</span><span v-else>y</span> {{ coverage }}<br />
      Royalty Percentage: {{ business.royaltyPercentage }}<br />
      Royalty Minimum: {{ business.royaltyMinimum | toUSD }}
    </b-col>
  </b-row>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
export default  {
  name: 'business-view',
  props: {
    business: {
      default: {
      }
    }
  },
  computed: {
    otherDirectors () {
      return this.business?.users?.filter((e) => e.id !== this.business.primaryContact.id) || []
    },
    numTerritories () {
      return (this.business?.coverage) ? this.business.coverage.length : 0
    },
    coverage () {
      if (this.numTerritories) {
        let coverage = this.business.coverage.map((e) => e.postalCode)
        return ': ' + coverage.join(', ')
      }
      return ''
    }
  },
  // methods: { // need username to perform loginAs
  //   ...mapActions('auth', ['loginAs']),
  //   doLoginAs (username) {
  //     this.loginAs(username).then(() => {
  //       this.$router.push('/')
  //     })
  //   },
  // }
}
</script>