<template>
  <div className="animated">
    <b-card>
      <b-card-header>
        <i class="icon-menu mr-1"></i>Franchise List <span class="float-right"><router-link :to="{ path: '/user/form', query: { role: 'ROLE_DIRECTOR' }}">New Franchise</router-link></span>
        <b-card-body>

          <b-row>
            <b-col>
              <b-form-select
                :options="[{ value: '1', text: 'Active' }, { value: '0', text: 'Inactive' }]"
                v-model="queryFilter.active"
              ></b-form-select>
            </b-col>
          </b-row>
          </b-card-body>
      </b-card-header>
      <b-card-body>
        <v-server-table
          name="dataTableBusinessList"
          :columns="columns"
          :useVuex="true"
          :url="url"
          :options="options"
          :theme="theme"
          id="dataTable"
          :preserveState="preserveState"
        >
          <div slot="uriName" slot-scope="props"><a :href="'http://' + sitePublicDomain + '/' + props.row.uriName" target="_blank">/{{ props.row.uriName }}</a></div>
          <div slot="owners" slot-scope="props">
            <template v-for="(user, index) in props.row.users">
            <div v-if="user.roles.includes('ROLE_DIRECTOR')" :key="index"><a href="#" @click="viewUid = user.id">{{ user.fullName }}</a></div>
            </template>
          </div>
          <div slot="active" slot-scope="props">
            {{ props.row.active ? "Yes" : "No" }}
          </div>
          <div slot="actions" slot-scope="props" class="text-center">
            <b-button-group>
              <b-btn size="sm" variant="info" @click="viewBid = props.row.id">View</b-btn>
              <!-- b-btn size="sm">Territories</b-btn -->
              <b-btn size="sm" variant="warning" :to="{ path: '/business/form', query: { bid: props.row.id }}">Edit</b-btn>
            </b-button-group>
          </div>

          <!-- div slot="child_row" slot-scope="props">
            This section will display other useful data related to the business.
          </div -->
        </v-server-table>
      </b-card-body>
    </b-card>
    <!-- view -->
    <BusinessViewModal :bid="viewBid" @business-view-modal-closed="viewBid = null" />
    <UserViewModal :uid="viewUid" @user-view-modal-closed="viewUid = null" />
    <CoverageUpdate />
  </div>
</template>

<script>
  import Vue from 'vue'
  import {HTTP} from '@/services/http-common'
  import { mapGetters } from 'vuex'
  // import {ClientTable, Event} from 'vue-tables-2-premium'
  import { ServerTable, Event } from 'vue-tables-2-premium'
  import { constants } from '@/shared/constants'
  import BusinessViewModal from '@/components/BusinessViewModal'
  import UserViewModal from '@/components/UserViewModal'
  import CoverageUpdate from '@/components/CoverageUpdate'
  Vue.use(ServerTable)

  export default {
    name: 'FranchiseList',
    components: {
      ServerTable,
      Event,
      BusinessViewModal,
      UserViewModal,
      CoverageUpdate
    },
    data: function () {
      return {
        columns: ['id', 'areaName', 'uriName', /* 'active', */ 'owners', 'actions'],
        options: {
          requestFunction (data) {
            return HTTP.get(this.url, {
                params: data
            }).catch(function (e) {
                this.dispatch('error', e)
            }.bind(this))
          },
          requestAdapter (data) {
            let query = {
              sort: data.orderBy ? data.orderBy : 'areaName',
              direction: data.ascending ? 'asc' : 'desc',
              pagination: true,
              page: data.page ? data.page : '1',
              itemsPerPage: data.limit ? data.limit : constants.LIST_DEFAULT_ITEMS_PER_PAGE
            }
            if (data.query !== '') {
              query.areaName = data.query.trim()
            }
            if (data.orderBy) {
              let param = 'order[' + data.orderBy + ']'
              query[param] = data.ascending ? 'asc' : 'desc'
            } else {
              query['order[id]'] = 'desc'
            }
            return query
          },
          responseAdapter ({data}) {
            return {
              data: data['hydra:member'],
              count: data['hydra:totalItems']
            }
          },
          headings: {
            id: 'BID',
            areaName: 'Location Name',
            uriName: 'URI',
            owners: 'Franchisee(s)',
            actions: 'Actions'
          },
          sortable: ['id', 'areaName', 'uriName', /* 'active', */ 'owners'],
          filterable: ['id', 'areaName', 'uriName', 'owners'],
          sortIcon: { base:'fa', up:'fa-sort-asc', down:'fa-sort-desc', is:'fa-sort' },
          pagination: {
            chunk: 5,
            edge: false,
            nav: 'scroll'
          },
          perPage: 30,
          perPageValues: [30,60,90]
        },
        preserveState: true,
        useVuex: true,
        theme: 'bootstrap4',
        template: 'default',
        viewBid: null,
        viewUid: null,
        queryFilter: {
          active: 1
        }
      }
    },
    computed: {
      ...mapGetters([
        'businessList'
      ]),
      url () {
        const params = {}
        for (const k in this.queryFilter) {
          if (this.queryFilter[k] !== null) params[k] = this.queryFilter[k]
        }
        const query = Object.keys(params).map(key => key + '=' + params[key]).join('&')
        return '/businesses?' + query
      }
    },
    methods: {
      // doGetBusinesses () {
      //   let params = {
      //     pagination: false
      //   }
      //   if (this.$route.query.bid) {
      //     params.id = this.$route.query.bid
      //   }
      //   this.$store.dispatch('getBusinesses', params)
      // }
    },
    watch: {
      startDate () {
        this.sourceConversions = {}
      }
    },
    created () {
      // this.doGetBusinesses()
    },
    destroyed() {
      this.$store.dispatch('resetBusinesses')
    }
  };
</script>

<style lang="scss">
  #dataTable {
    width: 95%;
    margin: 0 auto;

    .VuePagination {
      text-align: center;
      justify-content: center;
    }

    .vue-title {
      text-align: center ;
      margin-bottom: 10px;
    }

    .VueTables__search-field {
      display: flex;
    }
    .VueTables__search-field input {
      margin-left: 0.25rem;
    }

    .VueTables__limit-field {
      display: flex;
    }

    .VueTables__limit-field select {
      margin-left: 0.25rem !important;
    }

    .VueTables__table th {
      text-align: center;
    }

    .VueTables__child-row-toggler {
      width: 16px;
      height: 16px;
      line-height: 16px;
      display: block;
      margin: auto;
      text-align: center;
    }

    .VueTables__child-row-toggler--closed::before {
      content: "+";
    }

    .VueTables__child-row-toggler--open::before {
      content: "-";
    }
  }

</style>
